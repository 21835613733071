/* eslint-disable @typescript-eslint/no-explicit-any */

type ValidateUserPermissionsParams = {
  user: any;
  permissions: string[] | undefined;
  roles: string[] | undefined;
};

export function validateUserPermissions({
  user,
  permissions = [],
  roles = [],
}: ValidateUserPermissionsParams) {
  if (permissions.length > 0) {
    const hasAllPermissions = permissions?.every((permission) => {
      return user?.permissions?.includes(permission);
    });

    if (!hasAllPermissions) {
      return false;
    }
  }

  if (roles.length > 0) {
    const hasAllRoles = roles?.some((role) => {
      return user?.roles?.includes(role);
    });

    if (!hasAllRoles) {
      return false;
    }
  }

  return true;
}
