import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Can } from 'common/components/Can';
import { IMenuLink } from 'common/config/MenuConfig';
import { useAuth } from 'common/hooks/useAuth';
import { useCanCompany } from 'common/hooks/useCanCompany';
import React from 'react';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: IMenuLink;
  pathname: string;
  showTextButton: boolean;
  isActiveSubmenu?: boolean;
}

const LayoutMenuItem: React.FC<Props> = ({
  data,
  pathname,
  showTextButton,
  isActiveSubmenu = false,
}) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const isCompanyActive = useCanCompany();

  const [open, setOpen] = React.useState(true);

  const isSelectedActiveMenu =
    !(pathname !== '/' && data.path !== pathname) &&
    ((pathname === '/' && data.path === pathname) ||
      pathname.includes(data.path));

  const handleClick = (pathname: string, push: boolean) => {
    if (pathname === '/sign-out') {
      signOut();
      return;
    }

    if (push) {
      navigate(pathname);
    } else {
      setOpen(!open);
    }
  };

  return (
    <React.Fragment>
      <Can permissions={data.permissions}>
        <ListItemButton
          className='GroupButton'
          selected={isSelectedActiveMenu}
          disabled={data.premium && !isCompanyActive}
          onClick={() =>
            handleClick(data.path, !showTextButton || !data?.subLinks?.length)
          }
          sx={{
            '&:hover, &:focus': {
              '& svg.arrowDown': { opacity: open ? 1 : 0 },
            },
          }}
        >
          <ListItemIcon>
            <data.Icon />
          </ListItemIcon>
          <ListItemText
            primary={data.name}
            sx={{
              color: (theme) =>
                isSelectedActiveMenu ? theme.palette.secondary.dark : 'inherit',
              my: 0,
              display: showTextButton ? 'block' : 'none',
            }}
          />

          {showTextButton && isActiveSubmenu && !!data?.subLinks?.length && (
            <KeyboardArrowDown
              className='arrowDown'
              sx={{
                color: (theme) => theme.palette.secondary.main,
                mr: -1,
                opacity: 0,
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s',
              }}
            />
          )}

          {data.premium && !isCompanyActive && (
            <HiOutlineLockClosed color='inherit' />
          )}
        </ListItemButton>
        {showTextButton && isActiveSubmenu && (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding sx={{ paddingLeft: 2 }}>
              {data?.subLinks &&
                data.subLinks.map((item) => (
                  <React.Fragment key={item.path}>
                    <Can permissions={item.permissions}>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        className='SubButton'
                        onClick={() => navigate(item.path)}
                        disabled={item.premium && !isCompanyActive}
                      >
                        <ListItemIcon>
                          <item.Icon />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />

                        {item.premium && !isCompanyActive && (
                          <HiOutlineLockClosed color='inherit' />
                        )}
                      </ListItemButton>
                    </Can>
                  </React.Fragment>
                ))}
            </List>
          </Collapse>
        )}
      </Can>
    </React.Fragment>
  );
};

export { LayoutMenuItem };
