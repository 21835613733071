import {
  Box,
  Button,
  Divider,
  Stack,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';
import { useAuth } from 'common/hooks/useAuth';
import { HiX } from 'react-icons/hi';
import { LayoutFooter } from '../Footer';
import { LayoutSidebarProfile } from '../Sidebar/Profile/Profile';
import { LayoutMenuLinks } from './MenuLinks';

type Props = SwipeableDrawerProps;

const LayoutMenu = (props: Props) => {
  const { user } = useAuth();

  return (
    <SwipeableDrawer
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        },
        '& .MuiPaper-root': {
          backgroundColor: 'background.default',
        },
      }}
      {...props}
    >
      <Stack sx={{ minWidth: 250 }}>
        <Box sx={{ padding: 2, paddingTop: 6 }}>
          <Button
            color='inherit'
            startIcon={<HiX />}
            onClick={(e) => props.onClose(e)}
          >
            Menu
          </Button>
        </Box>

        <Divider />
      </Stack>

      <Stack
        spacing={2}
        sx={{ padding: 2, paddingLeft: 0 }}
        onClick={(e) => props.onClose(e)}
        onKeyDown={(e) => props.onClose(e)}
      >
        <LayoutSidebarProfile showInfo user={user} />

        <LayoutMenuLinks />
      </Stack>

      <LayoutFooter />
    </SwipeableDrawer>
  );
};

export { LayoutMenu };
