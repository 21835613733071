/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UserType } from 'common/types';
import { getErrorMessage } from 'common/utils/getErrorMessage';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from 'services/api';

type SignInType = {
  email: string;
  password: string;
  ip?: string;
};

interface AuthContextType {
  user: UserType | null;
  signIn: (
    user: SignInType,
    callback: VoidFunction,
    callbackError?: VoidFunction
  ) => void;
  signInNoRedirect: (
    user: SignInType,
    callback: VoidFunction,
    callbackError?: VoidFunction
  ) => void;
  signOut: (callback?: VoidFunction) => void;
  refresh: (callback?: VoidFunction) => void;
  isAuthenticated: boolean;
}

export function signOut() {
  localStorage.removeItem('application.auth.token');
  localStorage.removeItem('application.auth.refreshToken');
  localStorage.removeItem('application.auth.user');

  // authChannel.postMessage('signOut');
  window.location.reload();
  console.count();
}

const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const [user, setUser] = React.useState<UserType | null>(() => {
    const getUser = localStorage.getItem('application.auth.user');

    if (getUser) {
      return JSON.parse(getUser);
    }

    return null;
  });
  const isAuthenticated = !!user;

  const signOut = useCallback(
    (callback?: VoidFunction) => {
      localStorage.removeItem('application.auth.token');
      localStorage.removeItem('application.auth.refreshToken');
      localStorage.removeItem('application.auth.user');

      setUser(null);

      callback && callback();

      navigate('/login');
    },
    [history]
  );

  const loadUserLogged = useCallback(() => {
    api
      .get('/profile')
      .then((response) => {
        setUser({
          ...response.data,
          permissions: response.data?.role?.permissions.map(
            (permission: any) => permission.slug
          ),
        });
        localStorage.setItem(
          'application.auth.user',
          JSON.stringify(response.data)
        );
      })
      .catch(() => {
        signOut();
      });
  }, [signOut]);

  useEffect(() => {
    if (localStorage.getItem('application.auth.token')) {
      const token = localStorage.getItem('application.auth.token');
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      loadUserLogged();
    }
  }, [loadUserLogged]);

  const signIn = async (
    { email, password, ip }: SignInType,
    callback: VoidFunction,
    callbackError?: VoidFunction
  ) => {
    try {
      const response = await toast.promise(
        api.post('auth', {
          email,
          password,
          ip,
        }),
        {
          pending: 'Por favor aguarde! Carregando...',
          success: 'Olá, bem-vindo(a) 🎉',
        }
      );

      api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response.data?.token}`;
      localStorage.setItem('application.auth.token', response.data?.token);
      localStorage.setItem(
        'application.auth.refreshToken',
        response.data?.refreshToken
      );

      setUser({
        ...response.data?.user,
        permissions: response.data?.user?.role?.permissions.map(
          (permission: any) => permission.slug
        ),
      });
      localStorage.setItem(
        'application.auth.user',
        JSON.stringify(response.data?.user)
      );

      callback();
    } catch (error: any) {
      getErrorMessage(error);

      callbackError && callbackError();
    }
  };

  const signInNoRedirect = async (
    { email, password }: SignInType,
    callback: VoidFunction,
    callbackError?: VoidFunction
  ) => {
    try {
      const response = await toast.promise(
        api.post('auth', {
          email,
          password,
        }),
        {
          pending: 'Por favor aguarde! Cadastrando...',
        }
      );

      api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response.data?.token}`;
      localStorage.setItem('application.auth.token', response.data?.token);
      localStorage.setItem(
        'application.auth.refreshToken',
        response.data?.refreshToken
      );

      callback();
    } catch (error: any) {
      getErrorMessage(error);

      callbackError && callbackError();
    }
  };

  const refresh = useCallback(
    (callback?: VoidFunction) => {
      loadUserLogged();

      callback && callback();
    },
    [loadUserLogged]
  );

  const value = {
    user,
    signIn,
    signInNoRedirect,
    signOut,
    isAuthenticated,
    refresh,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthProvider, AuthContext };
