/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosError } from 'axios';
import { signOut } from 'contexts/AuthProvider';
import { getErrorMessage } from '../common/utils/getErrorMessage';

const endpoint = process.env.REACT_APP_BASE_API_URL || 'http://localhost:5000';

interface ResponseRefreshToken {
  token: string;
  refreshToken: string;
}

let isRefreshing = false;
let failedRequestsQueue: Array<{
  onSuccess: (token: string) => void;
  onFailure: (err: AxiosError) => void;
}> = [];

export function configApi() {
  // const getToken = localStorage.getItem("application.auth.token");

  const api = axios.create({
    baseURL: endpoint,
    headers: {
      // Authorization: `Bearer ${getToken}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (
          error.response.data?.code === 'Unauthorized' ||
          error.response.data?.message === 'Unauthorized'
        ) {
          // renovar o token
          const refreshToken = localStorage.getItem(
            'application.auth.refreshToken'
          );
          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post<ResponseRefreshToken>('/auth/refreshToken', {
                refreshToken,
              })
              .then((response) => {
                const { token } = response.data;

                localStorage.setItem('application.auth.token', token);

                if (response.data?.refreshToken) {
                  localStorage.setItem(
                    'application.auth.refreshToken',
                    response.data.refreshToken
                  );
                }

                api.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${token}`;

                failedRequestsQueue.forEach((request) =>
                  request.onSuccess(token)
                );
                failedRequestsQueue = [];
              })
              .catch((err) => {
                failedRequestsQueue.forEach((request) =>
                  request.onFailure(err)
                );
                failedRequestsQueue = [];

                getErrorMessage(err);

                signOut();
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (token: string) => {
                // originalConfig.headers.Authorization = `Bearer ${token}`;
                originalConfig.headers!.Authorization = `Bearer ${token}`;

                resolve(api(originalConfig));
              },
              onFailure: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        } else {
          // Error 401 mas não foi erro de autenticação

          getErrorMessage(error);
          // signOut();
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}
