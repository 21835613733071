import React from 'react';
import { Routes } from './config.routes';

export function CustomRouters() {
  return (
    <>
      <Routes />
    </>
  );
}
