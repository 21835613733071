/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';

export function getErrorMessage(error: any): void {
  if (error?.response?.data?.message) {
    if (Array.isArray(error.response.data.message)) {
      const messages = error?.response?.data?.message as string[];
      {
        messages.forEach((msg) => {
          toast.error(msg);
        });
      }

      return;
    }

    const message = error?.response?.data?.message?.includes('Cannot')
      ? 'Erro de comunicação! O Servidor está offline ou a rota chamada não existe.'
      : error?.response?.data?.message;

    toast.error(
      `${error?.response?.data?.statusCode === 500 ? '😱' : '😟'} ${message}`
    );
    return;
  }

  if (error?.response?.data) {
    toast.error(`😥 ${JSON.stringify(error?.response?.data, undefined, 2)}`);
    return;
  }
  const message = JSON.stringify(error?.message, undefined, 2);

  switch (message) {
    case '"Network Error"':
      toast.error(`🛑 Error de comunicação com o servidor.`);
      break;
    default:
      toast.error(`😭 ${message}`);
  }
}
