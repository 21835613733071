import { Avatar, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserType } from 'common/types';

interface Props {
  showInfo: boolean;
  user: UserType | null;
}

const LayoutSidebarProfile = ({ showInfo, user }: Props) => {
  const navigate = useNavigate();

  const size = showInfo ? 96 : 40;

  return (
    <Stack
      component='button'
      type='button'
      spacing={2}
      onClick={() => navigate('/profile')}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        border: 'none',
        cursor: 'pointer',
        transition: 'all 0.3s',
        '&:active': {
          transform: 'scale(0.9)',
        },
      }}
    >
      <Avatar
        src={user?.imagePath}
        alt={user?.name || 'Perfil'}
        title={user?.name || 'Perfil'}
        sx={{ width: size, height: size, border: `2px solid #FFF` }}
      />

      {showInfo && (
        <Stack>
          <Typography component='strong' sx={{ fontWeight: 500 }}>
            {user?.name}
          </Typography>
          <Typography
            component='strong'
            sx={{
              fontStyle: '0.875rem',
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            {user?.role.name}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export { LayoutSidebarProfile };
