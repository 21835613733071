import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import bgImg2 from 'common/assets/bolinhas-2.png';
import bgImg from 'common/assets/bolinhas.png';

const AuthLayout = () => {
  return (
    <Box
      sx={{
        background: `url(${bgImg})`,
        backgroundSize: '100px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflowY: 'auto',
      }}
    >
      <Outlet />

      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 200,
          background: `url(${bgImg2})`,
          backgroundSize: '50px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top right',
          display: 'block',
          height: 100,
          width: 100,
          zIndex: -1,
        }}
      />
    </Box>
  );
};

export { AuthLayout };
