/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from 'react';

interface SearchContextType {
  searchValue: string | null;
  handleSearch: (value: string | null) => void;
  handleSearchClear: () => void;
}

const SearchContext = React.createContext<SearchContextType>(null!);

function SearchProvider({ children }: { children: React.ReactNode }) {
  const [value, setValue] = React.useState<string | null>(null);

  const handleSearch = useCallback((value: string | null) => {
    setValue(value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setValue(null);
  }, []);

  return (
    <SearchContext.Provider
      value={{ searchValue: value, handleSearch, handleSearchClear }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export { SearchContext, SearchProvider };
