import { useCan } from 'common/hooks/useCan';
import { ReactNode } from 'react';

interface CanProps {
  children: ReactNode;
  permissions?: string[];
  roles?: string[];
}

export function Can({ children, permissions = [], roles = [] }: CanProps) {
  function hasPermissionsOrRoles(options: {
    permissions?: string[];
    roles?: string[];
  }) {
    return !!options?.permissions?.length || !!options?.roles?.length;
  }

  function showChildrenComponents(options: {
    permissions?: string[];
    roles?: string[];
  }) {
    return useCan({
      permissions: options?.permissions,
      roles: options?.roles,
    });
  }

  if (
    hasPermissionsOrRoles({ permissions, roles }) &&
    !showChildrenComponents({ permissions, roles })
  ) {
    return null;
  }

  return <>{children}</>;
}
