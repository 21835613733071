import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab, useTheme, Zoom } from '@mui/material';
import React from 'react';

const ScrollTop = () => {
  const theme = useTheme();
  const [offset, setOffset] = React.useState(0);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  React.useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset || 0);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // console.log(offset);

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Zoom
      in={offset >= 200}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${offset >= 200 ? transitionDuration.exit : 0}ms`,
      }}
      unmountOnExit
    >
      <Fab
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          backdropFilter: 'blur(8px)',
          color: (theme) => theme.palette.primary.main,
          bgcolor: 'rgba(255, 255, 255, 0.2)',
          '&:hover': {
            color: '#FFF',
            bgcolor: '#0890bab3',
          },
        }}
        className='base-box-shadow-medium'
        aria-label='Expand'
        color='inherit'
        size='medium'
        onClick={handleScrollTop}
      >
        <UpIcon />
      </Fab>
    </Zoom>
  );
};

export { ScrollTop };
