import 'moment/locale/pt-br';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { SearchProvider } from './contexts/SearchProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { CustomRouters } from './routers';

import './styles/global.css';
import theme from './styles/theme';

const client = new QueryClient();

moment.locale('pt-br');

export default function App() {
  return (
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <SearchProvider>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />

            <CustomRouters />

            <ToastContainer
              position='top-right'
              autoClose={8000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
            {/* The rest of your application */}
            <ReactQueryDevtools initialIsOpen={false} />
          </SearchProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
