import { List, ListSubheader, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { menus } from 'common/config/MenuConfig';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutMenuItem } from '../../Menu/MenuItem';

const Nav = styled(List)<{ component?: React.ElementType }>({
  '& .ListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
  },
  '& .ListItemButton-root.Mui-selected, &:hover .ListItemButton-root.Mui-selected':
    {
      backgroundColor: '#FFFFFF',
    },
  '& .Mui-selected': {
    position: 'relative',

    '& svg': {
      color: '#0890ba',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      height: '20px',
      width: 3,
      backgroundColor: '#0890ba',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
    color: 'inherit',
  },
  '& svg': {
    fontSize: 20,
  },
  '& .MuiListItemText-root .MuiTypography-root': {
    fontSize: '0.875rem',
    color: 'inherit',
    fontWeight: 500,
  },
});

interface Props {
  visibleDescriptionGroup: boolean;
  showTextButtons?: boolean;
}

const LayoutSidebarNavbar = ({
  visibleDescriptionGroup,
  showTextButtons,
}: Props) => {
  const { pathname } = useLocation();

  return (
    <Stack
      spacing={1}
      sx={{
        color: (theme) => theme.palette.secondary.main,
        '& .ListItemButton-root': {
          borderRadius: !visibleDescriptionGroup
            ? '0 8px 8px 0 !important'
            : undefined,
        },
      }}
    >
      {menus.map((group) => (
        <Nav
          key={group.groupName}
          sx={{ width: visibleDescriptionGroup ? '100%' : 'auto' }}
          component='nav'
          aria-labelledby={`nested-list-subheader-${group.groupName}`}
          subheader={
            visibleDescriptionGroup && (
              <ListSubheader
                component='div'
                id={`nested-list-subheader-${group.groupName}`}
                sx={{
                  position: 'relative',
                  bgcolor: 'transparent',
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                {group.groupName}
              </ListSubheader>
            )
          }
        >
          {group.links.map((item) => (
            <LayoutMenuItem
              key={item.path}
              data={item}
              pathname={pathname}
              showTextButton={showTextButtons || visibleDescriptionGroup}
              isActiveSubmenu
            />
          ))}
        </Nav>
      ))}
    </Stack>
  );
};

export { LayoutSidebarNavbar };
