/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { menus } from 'common/config/MenuConfig';
import { useSearch } from 'common/hooks/useSearch';
import { useCallback, useMemo, useRef } from 'react';
import { HiOutlineSearch, HiX } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

export function Search() {
  const { pathname } = useLocation();

  const showSearchComponent = useMemo(() => {
    const findMenu = menus.find((menu) => {
      const findLink = menu.links.find((link) => {
        if (link.hasSearch && link.path === pathname) {
          return link;
        }
      });

      return findLink;
    });

    return !!findMenu;
  }, [pathname]);

  const { searchValue, handleSearch, handleSearchClear } = useSearch();
  const inputRef = useRef<HTMLInputElement>(null);

  const debounce = useCallback((func: any, wait: number): any => {
    let timeout: NodeJS.Timeout;

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }, []);

  const returnedFunction = debounce((event: any) => {
    if (event?.target?.value) {
      // console.log(event.target.value);
      // setIsLoading(true);
      handleSearch(event.target.value);
    }
  }, 800);

  const handleClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    handleSearchClear();
  }, [handleSearchClear]);

  if (!showSearchComponent) return <></>;

  return (
    <Stack width={['100%', 'auto']}>
      <TextField
        name='search'
        defaultValue={searchValue}
        placeholder='Pesquisar...'
        type='text'
        size='small'
        onKeyUp={(event) => returnedFunction(event)}
        onKeyPress={(event) => returnedFunction(event)}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <HiOutlineSearch color='inherit' />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                disabled={!searchValue}
                color='secondary'
                onClick={handleClear}
                sx={{ opacity: searchValue ? 1 : 0, padding: 0, margin: 0 }}
              >
                <HiX color='inherit' />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          width: '100%',
          '& .MuiInputBase-root fieldset': {
            borderRadius: 2,
            borderColor: 'transparent',
          },

          '& .MuiInputBase-root:hover fieldset': {
            borderColor: 'transparent',
          },
        }}
      />
    </Stack>
  );
}
