import { List, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { menus } from 'common/config/MenuConfig';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutMenuItem } from '../Menu/MenuItem';

const Nav = styled(List)<{ component?: React.ElementType }>({
  '& .ListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: '0 8px 8px 0',
  },
  '& .ListItemButton-root.Mui-selected, &:hover .ListItemButton-root.Mui-selected':
    {
      backgroundColor: '#FFFFFF',
    },
  '& .Mui-selected': {
    position: 'relative',

    '& svg': {
      color: '#0890ba',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      height: '20px',
      width: 3,
      backgroundColor: '#0890ba',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
    color: 'inherit',
  },
  '& svg': {
    fontSize: 20,
  },
  '& .MuiListItemText-root .MuiTypography-root': {
    fontSize: '0.875rem',
    color: 'inherit',
    fontWeight: 500,
  },
});

const LayoutMenuLinks = () => {
  const { pathname } = useLocation();

  return (
    <Stack spacing={1} sx={{ color: (theme) => theme.palette.secondary.main }}>
      {menus.map((group) => (
        <Nav key={group.groupName} sx={{ width: '100%' }} component='nav'>
          {group.links.map((item) => (
            <LayoutMenuItem
              key={item.path}
              data={item}
              pathname={pathname}
              showTextButton
              isActiveSubmenu
            />
          ))}
        </Nav>
      ))}
    </Stack>
  );
};

export { LayoutMenuLinks };
