import { useAuth } from './useAuth';

export function useCanCompany() {
  const { user } = useAuth();

  if (!user) {
    return false;
  }

  return !!user?.company?.isActive;
}
